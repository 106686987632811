import { createExternalPromise } from "@/shared/lib/karrot-bridge/external-promise-loader/core";

import { getRegionInfo } from "@/entities/region/model/regionInfoStore";
import { getUserInfo } from "@/entities/user/model/userInfoStore";

import { KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY } from "@/features/karrot-bridge/constant/key";

export type GetUserInfo = Awaited<ReturnType<typeof getUserInfo>>;
export type GetRegionInfo = Awaited<ReturnType<typeof getRegionInfo>>;

export function createExternalPromiseBridgeInfo() {
  createExternalPromise({
    key: KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY.USER_INFO,
    api: getUserInfo,
  });

  createExternalPromise({
    key: KARROT_BRIDGE_EXTERNAL_PROMISE_LOADER_KEY.REGION,
    api: getRegionInfo,
  });
}
