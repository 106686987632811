import { defineConfig } from "@stackflow/config";

import { STACKFLOW_TRANSITION_DURATION } from "@/shared/constants/duration";

import { type Routes, routes } from "./routes";

export const config = defineConfig({
  activities: Object.entries(routes).map(([name, route]) => ({
    name: name as keyof Routes,
    route: {
      path: route.path,
    },
  })),
  transitionDuration: STACKFLOW_TRANSITION_DURATION,
});

type CommonParams = {
  entry?: string;
  referrer?: string;
};

type RouteParamsMap = {
  [K in keyof Routes]: (Routes[K] extends { params: infer P }
    ? P
    : Record<string, never>) &
    CommonParams;
};

declare module "@stackflow/config" {
  interface Register extends RouteParamsMap {}
}
