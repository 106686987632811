import {
  type IOpenLocalProfileSuggestionDeleteParams,
  type IOpenPoiSuggestionCreateParams,
} from "@daangn/bizprofile-sdk";
import { LOCAL_MAPS_UGC_WEBVIEW_PATHS } from "@daangn/local-profile-suggestion-sdk";

import { type PoiSuggestionCreateStepParams } from "@/widgets/local-profile-suggestion-create/types";

import { type ActivityCreateReviewParams } from "@/activities/reviews/new/ActivityCreateReview.layout";

interface Route {
  path: string;
  params: Record<string, any>;
}
export const routes = Object.freeze({
  /*
   * 유저 프로필
   * */

  // 유저 프로필 - 홈
  profile: {
    path: "/profiles/:profile_id",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 유저 프로필 - 온보딩 바텀시트
  "profile.onboarding_bottom_sheet": {
    path: "/profiles/:profile_id/onboarding-bottom-sheet",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 유저 프로필 - 후기 목록
  "profile.local_profile_reviews": {
    path: "/profiles/:profile_id/local-profile-reviews",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 유저 프로필 - 업체 제안 목록
  "profile.local_profile_suggestions": {
    path: "/profiles/:profile_id/local-profile-suggestions",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 유저 프로필 - 기여한 로컬프로필 목록
  "profile.contributed_local_profiles": {
    path: "/profiles/:profile_id/contributed-local-profiles",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 유저 프로필 - 기여 포인트 지급 내역
  "profile.contribution_points_history": {
    path: "/profiles/:profile_id/contribution-points-history",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 유저 프로필 - 이미지 모아보기
  "profile.contributed_images": {
    path: "/profiles/:profile_id/contributed-images",
    params: {} as {
      // 유저 ID
      profile_id: string;
    },
  },
  // 소셜 캐피탈 - 기여 포인트 (동네가이드 점수) 지급 내역 상세
  "profile.contribution_points_history.details": {
    path: "/profiles/:profile_id/contribution-points-history/contribution-points/:contribution_points_id",
    params: {} as {
      // 유저 ID
      profile_id: string;
      // 기여 포인트 ID
      contribution_points_id: string;
    },
  },

  /*
   * 후기
   * */

  // 후기 작성
  "review.create": {
    path: "/reviews/new",
    params: {} as ActivityCreateReviewParams,
  },
  // 후기 상세
  review: {
    path: "/reviews/:review_id",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 후기 발견
  "review.discover_local_profile": {
    path: "/reviews/:review_id/discover-local-profile",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 후기 수정
  "review.update": {
    path: "/reviews/:review_id/edit",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 후기 수정/삭제 액션 시트
  "review.action_sheet": {
    path: "/reviews/:review_id/action-sheet",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 후기 작성 후 랜딩페이지
  "review.landing": {
    path: "/reviews/:review_id/landing",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 후기 성과 페이지
  "review.performance_landing": {
    path: "/reviews/:review_id/performance-landing",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },

  /*
   * 로컬프로필
   * */

  // 로컬프로필 제안 참여 완료 후 랜딩페이지
  "local_profile_suggestion.landing": {
    path: "/local-profile-suggestions/:local_profile_suggestion_id/landing",
    params: {} as {
      // 로컬프로필 제안 ID
      local_profile_suggestion_id: string;
    },
  },
  // 로컬프로필 제안 검수 통과 페이지
  "local_profile_suggestion.inspection_completed": {
    path: "/local-profile-suggestions/:local_profile_suggestion_id/inspection-completed",
    params: {} as {
      // 로컬프로필 제안 ID
      local_profile_suggestion_id: string;
    },
  },
  // 로컬프로필에 UGC 생산하기 홈 (기여모드)
  "local_profile.contribute": {
    path: "/local-profiles/contribute",
    params: {},
  },
  // 로컬프로필 생성 제안
  "local_profile.suggestion.create": {
    path: "/local-profiles/suggestion/create",
    params: {} as Partial<
      Record<keyof IOpenPoiSuggestionCreateParams, string>
    > &
      PoiSuggestionCreateStepParams,
  },
  // 로컬프로필 삭제 제안
  "local_profile.suggestion.delete": {
    path: "/local-profiles/:local_profile_id/suggestion/delete",
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    } & Omit<
      IOpenLocalProfileSuggestionDeleteParams,
      "localProfileId" | "entry" | "referrer"
    >,
  },
  // 로컬프로필 생성 제안 완료
  "local_profile.suggestion.create_landing": {
    path: "/local-profiles/suggestion/create_landing",
    params: {} as {
      // 로컬프로필 제안 ID
      local_profile_suggestion_id: string;
      /**
       * 간단한 데이터를 위해 전역 상태를 선언하기 싫어서 쿼리파라미터로 대체해요. */
      created_local_profile_lat: string;
      created_local_profile_lng: string;
    },
  },
  // 로컬프로필 수정 제안 (deprecated - path 구조 변경 후 하위 호환을 위해)
  "local_profile.suggestion.deprecated_update": {
    path: "/local-profiles/:local_profile_id/update",
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 수정 제안
  "local_profile.suggestion.update": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
      /**
       * 수정 제안 항목 (comma separated string)
       * - 순서대로 렌더링
       * - 미지정 시 모든 항목 렌더링
       */
      fields?: string;

      /**
       * 진입한 퍼널 이름
       * - 수정 제안으로 오는 퍼널을 구분하기 위해 사용.
       * - referrer 보다 명시적으로 사용하기 위해 사용.
       * */
      funnel_name?: "local_profile_suggestion_create";
    },
  },
  // 로컬프로필 배경 사진 제안
  "local_profile.suggestion.update.background_image": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_BACKGROUND_IMAGE,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 가격 사진 제안
  "local_profile.suggestion.update.pricing_image": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_PRICING_IMAGE,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 사진 퍼스트 제안
  "local_profile.suggestion.update.image": {
    path: "/local-profiles/suggestion/update/image",
    params: {},
  },
  // 로컬프로필 장소 이름 제안
  "local_profile.suggestion.update.name": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_NAME,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 전화번호 제안
  "local_profile.suggestion.update.phone": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_PHONE,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 웹사이트 제안
  "local_profile.suggestion.update.website": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_WEBSITE,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 업종 제안
  "local_profile.suggestion.update.category": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_CATEGORY,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
      /**
       * 로컬프로필 이름
       */
      local_profile_name?: string;
    },
  },
  // 로컬프로필 주소 제안
  "local_profile.suggestion.update.address": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_ADDRESS,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // 로컬프로필 영업 시간 제안
  "local_profile.suggestion.update.operation_time": {
    path: LOCAL_MAPS_UGC_WEBVIEW_PATHS.LOCAL_PROFILE_SUGGESTION_UPDATE_OPERATION_TIME,
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },
  // UGC를 생산할 로컬프로필 추천 목록
  "local_profile.contribute.recommendations": {
    path: "/local-profiles/contribute/recommendations",
    params: {} as {
      contribution_type: "review_create" | "local_profile_update";
    },
  },
  // 로컬프로필 기여 목록
  "local_profile.contributions": {
    path: "/local-profiles/:local_profile_id/contributions",
    params: {} as {
      // 로컬프로필 ID
      local_profile_id: string;
    },
  },

  /*
   * 소셜 캐피탈
   * */

  // 소셜 캐피탈 - 후기 작성 완료
  "social_capital.review.landing": {
    path: "/social-capital/reviews/:review_id/landing",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 소셜 캐피탈 - 기여모드 후기 작성 완료
  "social_capital.review.contribute_landing": {
    path: "/social-capital/reviews/:review_id/contribute-landing",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 소셜 캐피탈 - 기여모드 제안 완료
  "social_capital.suggestion.contribute_landing": {
    path: "/social-capital/suggestions/:local_profile_suggestion_id/contribute-landing",
    params: {} as {
      // 로컬프로필 제안 ID
      local_profile_suggestion_id: string;
    },
  },

  /**
   * 소상공인의 날 후기 작성 이벤트 (동네지도 챌린지. 2024/11/12 ~ 2024/11/30)
   */

  // 소상공인의 날 후기 작성 이벤트: 퀄리티 스코어링 w/ LLM
  "small_business_day_event.review.quality_scoring": {
    path: "/small-business-day-event/reviews/:review_id/quality-scoring",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },
  // 소상공인의 날 후기 작성 이벤트: 당근머니 지급
  "small_business_day_event.review.money_reward": {
    path: "/small-business-day-event/reviews/:review_id/money-reward",
    params: {} as {
      // 후기 ID
      review_id: string;
    },
  },

  /*
   * 404 페이지
   * */

  // 존재하지 않는 페이지 접근
  "error.not_found": {
    path: "/404",
    params: {},
  },
} as const satisfies Record<string, Route>);

export type Routes = typeof routes;
