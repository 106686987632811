import pRetry from "p-retry";
import { createStore } from "zustand/vanilla";
import { type KarrotBridge } from "@daangn/karrotbridge";

import { IS_DEV } from "@/shared/constants/environment";
import { bridge } from "@/shared/lib/karrot-bridge/bridge";
import { createSelectorsVanilla } from "@/shared/utils/zustand/createSelectorsVanilla";

type UserInfo = Awaited<
  ReturnType<KarrotBridge["getUserInfo"]>
>["info"]["user"];

type State = {
  user: UserInfo | null;
};
type Action = {
  setUser: (user: NonNullable<State["user"]>) => void;
};
export const userInfoStore = createStore<State & Action>()((set) => ({
  user: null,
  setUser: (user) => set({ user }),
}));

export const useUserInfoStore = createSelectorsVanilla(userInfoStore);

const _getMockUserInfo = () => {
  return new Promise<UserInfo>((resolve) => {
    setTimeout(() => {
      resolve({
        authToken: import.meta.env.VITE_USER_AUTH_TOKEN,
        id: +import.meta.env.VITE_USER_ID,
        nickname: "제이슨개발환경",
        hashId: "",
        phone: "",
        profileImage: "",
        userExtend: {
          region1: {
            id: 6128,
            name1: "서울특별시",
            name2: "서초구",
            name3: "서초동",
            name1Id: 1,
            name2Id: 362,
            name3Id: 6128,
            name: "서초동",
            centerCoordinates: {
              latitude: 37.495,
              longitude: 127.024,
            },
            parentId: 362,
            fullname: "서울특별시 서초구 서초동",
          },
        },
      });
    }, 1000);
  });
};

export const getUserInfo = async () => {
  const fetch = async () => {
    return (
      IS_DEV
        ? await _getMockUserInfo()
        : (await bridge.getUserInfo({})).info.user
    ) satisfies UserInfo;
  };

  const user = await pRetry(fetch, { retries: 3 });

  userInfoStore.setState({ user: user });

  return user;
};
