import { IS_DEV, IS_PROD, STAGE } from "@/shared/constants/environment";
import { datadogRUM } from "@/shared/lib/datadog/datadog";
import { sentry } from "@/shared/lib/sentry/sentry";
import { getVConsole } from "@/shared/lib/v-console/vconsole";

import { IS_DEBUG_APP } from "@/entities/karrot/lib/extractAppBuildType";

import { createExternalPromiseBridgeInfo } from "@/features/karrot-bridge/model/createExternalPromiseBridgeInfo";

import "./shared/styles";

import { checkInvalidReferrer } from "./app/checkInvalidReferrer";
import { main } from "./app/entry";
import "./shared/styles/normalize.css";
/**
 * 참고:
 * 공용으로 사용되는 CSS를 index.ts에 포함시켜서,
 * Code Splitting으로 인한 과도한 번들 사이즈 증가를 막습니다.
 */
import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "@daangn/sprout-components-checkbox/index.css";
import "@daangn/sprout-components-button/index.css";
import "@daangn/sprout-components-spinner/index.css";
import "@daangn/sprout-components-text-field/index.css";
import "@daangn/sprout-components-avatar/index.css";
import "@daangn/sprout-components-callout/index.css";
import "@daangn/sprout-components-dialog/index.css";
import "@daangn/sprout-components-snackbar/index.css";
import "@daangn/sprout-components-divider/index.css";
import "@daangn/sprout-components-radio-group/index.css";
import "@daangn/sprout-components-chips/index.css";
import "@daangn/sprout-components-help-bubble/index.css";
import "@daangn/business-review-ui/index.css";

if (STAGE === "production" || STAGE === "alpha") {
  // Sentry 초기화
  sentry.initialize();
  // Datadog RUM 초기화
  datadogRUM.initialize();
}

if (IS_DEBUG_APP || IS_DEV) {
  getVConsole();
}

/**
 * Invalid Referrer를 체크합니다. (alpha 환경에서만)
 */
if (!IS_PROD && STAGE === "alpha") {
  checkInvalidReferrer();
}

createExternalPromiseBridgeInfo();

// Render App
main();
