import { makeTemplate } from "@stackflow/plugin-history-sync";

import { config } from "@/stackflow/stackflow.config";

/**
 * 엔트리 파일에서 리액트 앱 초기화와 API 요청을 동시에 수행해 초기 렌더링까지 걸리는 시간을 단축해요.
 * https://stackflow.so/ko/api-references/future-api/api-pipelining
 */
export async function main() {
  let initialLoaderData: unknown | null = null;

  for (const activity of config.activities) {
    const t = makeTemplate({ path: activity.route.path });
    const match = t.parse(window.location.pathname + window.location.search);

    if (!match) {
      continue;
    }

    if ("loader" in activity && typeof activity.loader === "function") {
      // 1. API 데이터를 요청해요 (await하지 않아요)
      initialLoaderData = activity.loader({ params: match });
    }

    break;
  }

  // 2. 동시에 리액트 앱을 다운로드 받아요.
  const { renderApp } = await import("./renderApp");

  // 3. 둘을 결합해요.
  renderApp({ initialLoaderData });
}
