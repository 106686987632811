/**
 * 비즈니스 리뷰 URL을 로컬 비즈니스 UGC URL로 리다이렉트하는 유틸리티
 */

// 비즈니스 리뷰 URL
const BUSINESS_REVIEW_URL_PRODUCTION = "business-review.karrotwebview.com";
const BUSINESS_REVIEW_URL_ALPHA = "business-review.alpha.karrotwebview.com";

// 로컬 비즈니스 UGC URL
const LOCAL_BUSINESS_UGC_URL_PRODUCTION =
  "local-business-ugc.karrotwebview.com";
const LOCAL_BUSINESS_UGC_URL_ALPHA =
  "local-business-ugc.alpha.karrotwebview.com";

const REDIRECT_MAPPING: Record<string, string> = {
  [BUSINESS_REVIEW_URL_ALPHA]: LOCAL_BUSINESS_UGC_URL_ALPHA,
  [BUSINESS_REVIEW_URL_PRODUCTION]: LOCAL_BUSINESS_UGC_URL_PRODUCTION,
};

/**
 * URL을 확인하여 필요한 경우 리다이렉트 URL을 반환합니다.
 * 명시적으로 REDIRECT_MAPPING에 정의된 호스트만 리다이렉트합니다.
 *
 * @param currentUrl 현재 URL 문자열
 * @returns 리다이렉트할 URL 또는 변경이 필요 없는 경우 원래 URL
 */
export function checkAndRedirect(currentUrl: string): string {
  try {
    const url = new URL(currentUrl);
    const targetHostname = REDIRECT_MAPPING[url.hostname];

    if (targetHostname) {
      url.hostname = targetHostname;

      return url.toString();
    }

    return currentUrl;
  } catch {
    return currentUrl;
  }
}

/**
 * 현재 페이지의 URL을 확인하고 필요한 경우 리다이렉트를 수행합니다.
 */
export function handleRedirect(): void {
  const currentUrl = window.location.href;
  const redirectUrl = checkAndRedirect(currentUrl);

  if (redirectUrl !== currentUrl) {
    window.location.href = redirectUrl;
  }
}

// 리다이렉트 즉시 실행
handleRedirect();
