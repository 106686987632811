import pRetry from "p-retry";
import { createStore } from "zustand";
import { type KarrotBridge } from "@daangn/karrotbridge";

import { IS_DEV } from "@/shared/constants/environment";
import { bridge } from "@/shared/lib/karrot-bridge/bridge";
import { createSelectorsVanilla } from "@/shared/utils/zustand/createSelectorsVanilla";

type RegionInfo = Awaited<
  ReturnType<KarrotBridge["getRegionInfo"]>
>["info"]["region"];

type State = {
  region: RegionInfo | null;
};
type Action = {
  setRegion: (region: NonNullable<State["region"]>) => void;
};
const regionInfoStore = createStore<State & Action>()((set) => ({
  region: null,
  setRegion: (region) => set({ region }),
}));
export const useRegionInfoStore = createSelectorsVanilla(regionInfoStore);

const _getMockRegionInfo = () => {
  return new Promise<RegionInfo>((resolve) => {
    setTimeout(() => {
      resolve({
        id: +import.meta.env.VITE_USER_REGION_ID,
        name: "서초 4동",
        centerCoordinates: { latitude: 0, longitude: 0 },
        fullname: "",
        name1: "",
        name1Id: 0,
        name2: "",
        name2Id: 0,
        name3: "",
        name3Id: 0,
        parentId: 0,
      });
    }, 1000);
  });
};

export const getRegionInfo = async () => {
  const fetch = async () => {
    return (
      IS_DEV
        ? await _getMockRegionInfo()
        : (await bridge.getRegionInfo({})).info.region
    ) satisfies RegionInfo;
  };

  const region = await pRetry(fetch, { retries: 3 });

  regionInfoStore.setState({ region: region });

  return region;
};
